import React from 'react'
import { styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'

const SectionModal = ({open, close, backgroundColor, children}) => {
    const theme = useTheme()
    const Modal = styled('div')({
        width:'100vw',
        height:'100vh',
        display:'flex',
        position:'absolute',
        background:`linear-gradient(${theme.palette.gradients.frostbite},${theme.palette.gradients.frenchViolet})`,
        top:0,
        left:0,
        zIndex:3,
        padding:'10px',
        opacity:open ? 1 : 0,
        zIndex:open ? 15 : -1
    })
    const DelayedDiv = styled('div')({
        '@keyframes delayAppear':{
            from:{
                opacity:0,
            },
            to:{
                opacity:1,
            }
        },
        width:'100%',
        height:'100%',
        opacity:0,
        backgroundColor:backgroundColor || theme.palette.gradients.raisinblack,
        animation:'delayAppear .05s linear .1s forwards',
        position:'relative'
    })
  return (
    <Modal>
        {open 
        && 
        <DelayedDiv>
            {
                children
            }
        </DelayedDiv>
        }
    </Modal>
  )
}

export default SectionModal